/*!
 *  HERO
 */

.hero {
  &.full-height {
    min-height: calc(100vh - 120px);
    height: auto;
    width: 100%;
    padding-top: 80px;
    position: relative;
  }
  &.hero-home {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 120px;
    .bk-waved {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 130% 100%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        background-size: cover;
      }
    }

    .wrapp-all-hero {
      min-height: 100vh;
      .hero-block {
        position: relative;
        margin-top: 50px;
        z-index: 2;
        @include media-breakpoint-down(sm) {
          margin-top: 50px;
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 50px;
          padding-bottom: 0;
        }

        .wrapp-hero-block {
          position: relative;
          .wrapp-logo-image {
            position: absolute;
            top: 30%;
            width: 100%;
            img {
              width: 100%;
            }
            @include media-breakpoint-up(xl) {
              top: 20%;
            }
          }
          .logo-bk {
            position: absolute;
            top: -5%;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: 2;
          }
          .neon {
            position: relative;
            width: 100%;
            height: 300px;
            margin: 0 auto;
            z-index: 2;
            @include media-breakpoint-up(xl) {
              margin: 0px auto 100px;
            }
            @include media-breakpoint-down(md) {
              width: 80%;
            }
            @include media-breakpoint-down(xs) {
              width: 90%;
            }
            &::before {
              @include box-shadow(inset 0px 0px 50px $white);
            }
            &::after {
              @include box-shadow(0px 0px 50px $white);
            }
            &::before, &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              @include border-radius(50%);
              border: 100px solid $white;
              overflow: hidden;

              @include media-breakpoint-down(xs) {
                border-width: 50px;
              }
            }
          }
          .iphone-left, .iphone-right {
            position: absolute;
            width: 100%;
            top: 0;
            max-width: 260px;
            z-index: 2;
            img {
              width: 100%;
              height: auto;
            }
            @include media-breakpoint-up(xl) {
              max-width: 350px;
            }
            @include media-breakpoint-down(md) {
              max-width: 280px;
            }
            @include media-breakpoint-down(sm) {
              max-width: 230px;
            }
            @media (max-width: 500px) {
              max-width: 200px;
            }
            @media (max-width: 450px) {
              max-width: 190px;
            }
            @media (max-width: 400px) {
              max-width: 170px;
            }
          }
          .iphone-left {
            top: 25%;
            left: 28%;
            @include media-breakpoint-up(xl) {
              left: 27%;
            }
            @include media-breakpoint-down(md) {
              left: 20%;
            }
            @include media-breakpoint-down(sm) {
              left: 15%;
            }
            @media (max-width: 450px) {
              left: 10%;
            }
            @media (max-width: 380px) {
              left: 0%;
            }
          }
          .iphone-right {
            left: 48%;
            @include media-breakpoint-down(sm) {
              left: 45%;
            }
            @media (max-width: 450px) {
              left: 42%;
            }
          }
        }
      }
    }
    .wrapp-cup {
      position: absolute;
      top: 40%;
      left: 85%;
      z-index: 2;
      max-width: 100px;

      @include media-breakpoint-down(sm) {
        max-width: 80px;
      }
    }

    .wrapp-cta-app {
      position: relative;
      z-index: 2;
    }
  }
  &.hero-business {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    position: relative;
    .bk-waved {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 130% 100%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;

      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    }
    .wrapp-all-hero {
      position: relative;
      z-index: 2;
      .wrapp-images {
        position: relative;
        .neon {
          position: relative;
          top: -5%;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          &::before {
            @include box-shadow(inset 0px 0px 30px $white);
          }
          &::after {
            @include box-shadow(0px 0px 30px $white);
          }
          &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include border-radius(50%);
            border: 100px solid $white;
            overflow: hidden;
            @include media-breakpoint-down(xs) {
              border-width: 80px;
            }
          }
        }
        .wrapp-ipad-anim {
          position: absolute;
          top: 150px;
          right: -200px;
          width: 100%;
          z-index: 2;
          @include media-breakpoint-down(xs) {
            top: 70px;
            right: -100px;
          }
          .ipad-left {
            @include transform(rotate(15deg));
            img {
              @include transform(translateX(0px));
              @include media-breakpoint-down(md) {
                max-width: 600px;
                width: 100%;
              }
            }
          }
        }
        .wrapp-iphone-anim {
          position: absolute;
          top: 100px;
          width: 300px;
          z-index: 2;
          @include media-breakpoint-down(xs) {
            top: 50px;
            width: 200px;
          }
          .iphone-left {
            img {
              @include transform(translateX(0px));
              @include media-breakpoint-down(md) {
                max-width: 600px;
                width: 100%;
              }
            }
          }
        }
        .wrapp-muffin {
          position: absolute;
          bottom: -50px;
          left: -150px;
          @include transform(rotate(70deg));
          z-index: 2;
          @include media-breakpoint-down(md) {
            bottom: 50px;
            left: 50px;
          }
          img {
            max-width: 200px;
            @include media-breakpoint-down(md) {
              max-width: inherit;
              width: 130px;
            }
            @include media-breakpoint-down(xs) {
              max-width: inherit;
              width: 70px;
            }
            @media (max-width: 450px) {
              width: 50px;
            }
          }
        }
        .wrapp-pizza {
          position: absolute;
          top: 0;
          left: -40px;
          z-index: 2;
          @include media-breakpoint-down(md) {
            left: auto;
            right: 0;
            top: 60px;
            @include transform(scaleX(-1) rotate(15deg));
          }
          @include media-breakpoint-down(xs) {
            left: auto;
            right: 0;
            top: 10px;
          }
          img {
            max-width: 200px;
            width: 100%;
            @include media-breakpoint-down(md) {
              max-width: inherit;
              width: 110px;
            }
            @include media-breakpoint-down(xs) {
              width: 70px;
            }
            @media (max-width: 450px) {
              width: 50px;
            }
          }
        }
      }

      .wrapp-title {
        @include media-breakpoint-down(md) {
          text-align: center;
          margin-top: 40px;
        }
        .title {
          font-size: 39px;
          padding-bottom: 5%;
          line-height: 1.1;
          @include media-breakpoint-down(md) {
            font-size: 30px;
          }
        }

        .subtitle {
          font-size: 20px;
          line-height: 1.5;
          margin-bottom: 15px;
          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        
        }
      }
      .wrapp-content {
        .subtitle {
          font-size: 18px;
          line-height: 1.5;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 15px;
          margin-bottom: 15px;
          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 100px;
        }
      }
      .highlights {
        padding: 6px 0;
        text-align: left;
        font-weight: normal;
        
        @include media-breakpoint-down(sm) {
          font-size: 13px;
          justify-content: center;
          .list-element{
            margin-right: 15px;
          }
          .col-4{
          line-height: 1.2em;
          }

        }
        .icon {
          width: 30px;
          @include media-breakpoint-down(md) {
            width: 20px;
          }
        }
      }
    }
  }
  &.hero-chuzeat-app {
    .block-text {
      padding-top: 100px;
      
      @include media-breakpoint-down(md) {
        padding-top: 70px;
      }
      @include media-breakpoint-down(sm) {
        padding-top: 30px;
      }
      .wrapp-title {
        br {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .wrapp-illustration {
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: block;
      }
    }
    .wrapp-media {
      .wrapp-illustration {
        svg {
          max-height: 550px;
        }
      }
    }
  }
  &.hero-contacts {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    padding-top: 150px;
    @include media-breakpoint-down(md) {
      padding-top: 100px;
    }
    .bk-waved {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 130% 100%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        background-size: cover;
      }
    }
    .wrapp-all-hero {
      .hero-block {
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(sm) {
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(xs) {
          padding-bottom: 0;
        }

        .wrapp-hero-content {
          @include media-breakpoint-down(md) {
            margin: 20px 0px 150px;
          }
          @include media-breakpoint-down(sm) {
            margin: 20px 0px 80px;
          }
        }
      }
    }
  }
  &.hero-social {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 150px;
    @include media-breakpoint-down(md) {
      padding-top: 100px;
    }
    .bk-waved {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 130% 100%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;

      @include media-breakpoint-down(md) {
        background-size: cover;
      }
    }

    .wrapp-all-hero {
      min-height: 100vh;
      .hero-block {
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(sm) {
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(xs) {
          padding-bottom: 0;
        }

        .wrapp-hero-block {
          position: relative;
          .wrapp-logo-image {
            position: absolute;
            top: 30%;
            width: 100%;
            img {
              width: 100%;
            }
            @include media-breakpoint-up(xl) {
              top: 20%;
            }
          }
        }

        .row-intro {
          margin: 150px 0px;

          @include media-breakpoint-down(md) {
            margin: 50px 0px 150px;
          }
          @include media-breakpoint-down(sm) {
            margin: 50px 0px;
          }
        }
      }
    }
  }
  &.hero-about {
    min-height: calc(100vh - 100px);
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 120px;
    .bk-waved {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 130% 100%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        background-size: cover;
      }
    }

    .wrapp-all-hero {
      padding-top: 50px;
      @include media-breakpoint-down(md) {
        padding-top: 30px;
      }
      @include media-breakpoint-down(md) {
        padding-top: 20px;
      }
      .hero-block {
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(sm) {
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(xs) {
          padding-bottom: 50px;
        }

        .wrapp-content {
          margin-bottom: 150px;
          @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
          }
          .call-to-foodea {
            border-color: $institutional;
            background-color: $white;
            color: $institutional;
            @include transition(all 0.3s ease-in-out);
            &:hover {
              opacity: 1 !important;
              background: $institutional;
              color: $white;
              @include transition(all 0.3s ease-in-out);
            }
          }
        }

      }
    }
  }
  &.hero-faq {
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 100px;
  }
  &.hero-condition {
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 100px;

    .list-wrapp {
      > li {
        position: relative;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .ul-list {
      list-style-type: none;
      padding-inline-start: 60px;
      li {
        position: relative;
        margin-top: 10px;
        .sublist-point {
          position: absolute;
          left: -60px;
        }
      }
    }
  }
  &.hero-privacy {
    height: auto;
    width: 100%;
    position: relative;
    z-index: 3;
    padding-top: 100px;

    .list-wrapp {
      padding: 0px 15px;
       > li {
        position: relative;
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .ul-list {
      list-style-type: none;
      padding-inline-start: 60px;
      li {
        position: relative;
        margin-top: 10px;
        .sublist-point {
          position: absolute;
          left: -60px;
        }
      }
    }
  }
  &.hero-not-found {
    position: relative;
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: $institutional;

    .wrapp-main-text-404 {
      font-size: 300px;
      line-height: 100%;
      display: block;
      color: $white;
      @extend .f-black;
      @include media-breakpoint-down(sm) {
        font-size: 250px;
      }
    }
  }
}
