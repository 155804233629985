/*!
 *  HEADER
 */

header {
  width: $full;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 40px 0px;

  .list-items {
    .nav-item {
      a {
        position: relative;
        &.active {
          @extend .f-bold;
          &:after {
            content: "";
            position: absolute;
            top: -7px;
            right: -7px;
            width: 8px;
            height: 8px;
            @include border-radius(5px);
            background-color: $institutional;
          }
        }
      }
    }
  }

  &.header-white {
    .list-items {
      .nav-item {
        color: $black;
        margin-right: 20px;
        font-size: $rem-14px;
        a {
          color: $white;
        }

        &:last-of-type {
          margin-right: 0px;
        }

        .call-to-restaurant {
          border-color: $white;
          color: $white;
          &:hover {
            opacity: 1 !important;
            background: $white;
            color: $institutional;
          }
        }
      }
    }
    .wrapp-menu {
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        background-color: $white !important;
      }
    }
  }
  &.header-orange {
    .list-items {
      .nav-item {
        color: $black;
        margin-right: 20px;
        font-size: $rem-14px;
        a {
          color: $black;
        }
        &:last-of-type {
          margin-right: 0px;
        }

        .call-to-restaurant {
          border-color: $institutional;
          color: $institutional;
          &:hover {
            opacity: 1 !important;
            background: $institutional;
            color: $white;
          }
        }
      }
    }
  }

  .wrapp-menu {
    .hamburger-inner:before {
      width: 20px;
      margin-left: 10px;
    }
    .hamburger-inner, .hamburger-inner:after {
      width: 30px;
    }
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
      height: 3px;
      background-color: $text-black;
    }
    .hamburger {
      padding-right: 0px;
      &:focus {
        outline: none;
      }

      &.is-active {
        .hamburger-inner:before {
          width: 30px;
          margin-left: 0px;
        }
      }
    }
  }

  /*!
 *  Style for Language Switcher (Privacy & Cookie Policies only)
 */
  #lang-switcher {

    button {
    background-image: url(/it/images/icons/icon-languages.svg);
    background-repeat: no-repeat;
    background-position: 4px 50%;

    &::after {
      display: none!important;
      }
    }
    button.dropdown-toggle {
    border-radius: 8px;
    border-color: #000;
    color: $text-black;
    width: 80px;
    padding-left: 28px;
  }
  .dropdown-menu {
    width: 80px;
    min-width:auto;
    border-radius: 8px;
    padding: 0;
    margin: 1px 0 0 0;
    a.dropdown-item {
      padding: 0.375rem 0.75rem;
      width: 100%;
      padding-left: 28px;
      color: #000;
      cursor: pointer;
      &:hover {
        border-radius: 8px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-right: 20px;
  }
}




}

/*!
 *  Style for Top Sticky Bar to promote free service during covid lockdown
 */

 .sticky-offert {

  background-color: #fa453b;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  p{
    margin: 0;
    color: #FFFF;
    text-align: center;
  }
  a{
    color:#FFFF;
    padding-left: 20px;
    text-decoration: underline;
    white-space: nowrap;
  }
  @media only screen and (max-width: 600px) {

    height: 80px;
    
  }
}

.offert-hide {

  display:none;

}
