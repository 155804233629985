/*!
 *  FOOTER
 */
footer {
    margin-top: 100px;
    padding: 100px 0px 60px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    background-color: $footer-grey;
    @include border-top-left-radius(50px);
    @include border-top-right-radius(50px);
    //margin-top: 100px;

    .nav {
      margin: 0;
      li {
        margin-bottom: 10px;
        font-size: $rem-14px;
      }
    }

    .copyright {
      p {
        margin: 0;
        font-size: $rem-14px;
      }
    }

    .wrapp-social {
      a {
        display: inline-block;
        img {
          height: 17px;
        }
      }
    }
 }

#fullscreen-menu {
 position: fixed;
 bottom: 0;
 left: 0;
 width: $full;
 height: 100%;
 z-index: 999;
 background: transparent;
 // background: $white;
 margin-top: 100px;
 padding: 0px 20px;
 overflow: auto;
  .wrapp-header {
    width: $full;
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px 0px;

    .wrapp-menu {
      .hamburger-inner:before {
        width: 20px;
        margin-left: 10px;
      }
      .hamburger-inner, .hamburger-inner:after {
        width: 30px;
      }
      .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        height: 3px;
      }
      .hamburger {
        padding-right: 0px;
        &:focus {
          outline: none;
        }

        &.is-active {
          .hamburger-inner:before {
            width: 30px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .wrapp-fullscreen-menu {
    margin-top: 120px;
    margin-bottom: 40px;
   .wrapp-links {
     .nav {
       margin-top: 6%;
       li {
         @extend .f-bold;
         font-size: $rem-28px;
         margin: 25px 0px 25px;

         @include media-breakpoint-down(xs) {
           font-weight: 600;
           font-size: $rem-22px;
           margin: 20px 0px 15px;
         }

         a {
           color: $text-black;
         }
       }
     }
   }
   .wrapp-social {
     li {
       a {
         display: inline-block;
         img {
           width: 26px;
           height: 26px;
         }
       }
     }
   }
  }
}



@media screen and (max-width: 845px) {
  .partners {
    img {
      width: 60%;
    }

}

@media screen and (min-width: 845px) {
  
    
  }


}
