// Import Modules
@import '../_modules/link/link';

// Bower files
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import '../../node_modules/swiper/src/swiper.scss';

///*** FONT ***///
@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,800,900&display=swap');

///*** VARIABLES ***///
@import "common/variables";

///*** MIXIN ***///
@import "mixins/mixins";

///*** EXTENSION ***///
@import "extensions/extensions";

///*** GLOBAL ***///
@import "common/global";

///*** COMPONENTS ***///
@import "components/buttons";
@import "components/links";
@import "components/typography";
@import "components/images";
@import "components/forms";

///*** WRAPPER PAGES & POSTS ***///
@import "layouts/header";
@import "layouts/pages";
@import "layouts/hero";
@import "layouts/posts";
@import "layouts/footer";

///*** EMBEDDED RESOURCES ***///
@import "extra/iubenda";