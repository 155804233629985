// buttons layout
.btn {
  @include border-radius(100px);
  @include transition(all 0.4s ease);
  background-color: inherit;
}

.btn-global {
  padding: 7px 30px;
  font-size: $rem-14px;
  display: inline-block;
}

.btnn-big {
  padding: 25px 20px;
  max-width: 300px;
  width: 100%;
  min-width: 35px;
  @include border-radius(50px);
  font-size: $rem-16px;
  display: inline-block;
  background-color: $grey2;
  color: $white;
  border: none;
  letter-spacing: 0.7px;

  &:hover, &:focus {
    cursor: pointer;
    background-color: $grey3;
    color: $white;
  }

  &.tag {
    background-color: $grey6;
    color: $grey2;
    margin: 10px;

    &:hover, &:focus {
      background-color: $grey4;
      color: $grey2;
    }
  }
}
.btnn-medium {
  padding: 10px 35px;
  width: auto;
  min-width: 30px;
  @include border-radius(20px);
  font-size: $rem-14px;
  display: inline-block;
  background-color: $grey2;
  color: $white;
  border: none;

  &:hover, &:focus {
    cursor: pointer;
    background-color: $grey3;
    color: $white;
  }

  &.tag {
    background-color: $grey6;
    color: $grey2;
    margin: 10px;

    &:hover, &:focus {
      background-color: $grey4;
      color: $grey2;
    }
  }
}
.btnn-send {
   padding: 5px 30px;
   top: 5px;
   position: relative;
   height: 80%;
   width: 100%;
   min-width: 30px;
   @include border-radius(4px);
   text-transform: uppercase;
   font-size: 0.75rem;
   display: inline-block;
   border: 2px solid transparent;
   @extend .f-semibold;

  &:hover, &:focus {
    cursor: pointer;
    color: $institutional;
    opacity: 0.8;
  }
}

.btnn-small {
  padding: 0px 30px;
  width: auto;
  min-width: 30px;
  @include border-radius(20px);
  font-size: $rem-10px;
  display: inline-block;
  background-color: $grey3;
  color: $white;
  border: none;
  line-height: 30px;

  &:hover, &:focus {
    cursor: pointer;
    background-color: $grey4;
    color: $white;
  }
}


.btn-outline-white {
  border-color: $white;
  color: $white;
  background: transparent;

  &:hover {
    opacity: 0.7;
    @include transition(all 0.4s ease);
  }
}

.btnn-plans {
  padding: 0px 30px;
  width: 100%;
  min-width: 30px;
  //@include border-radius(6px);
  @include border-radius(25px);
  font-size: $rem-13px;
  display: inline-block;
  background: linear-gradient(90deg, #FB413E 0%, #FB6139 100%);
  color: $white;
  
  line-height: 30px;

  &:hover, &:focus {
    cursor: pointer;
    background: linear-gradient(90deg, $grey4 0%, $grey4 100%);
    color: $white;
  }
}


.btn-outline-institutional {
  border: 1px solid $institutional;
  color: $institutional;
  background: white;

  &:hover {
    background-color: $grey4;
    border-color: $grey4;
    color: $white;
  }
}

.button-separator {

  @include media-breakpoint-down(xs) {
    display: none;
  }

}
