a {
  @include transition(opacity 0.3s ease-in-out);
  &.discover-more {
    color: $institutional;
    display: inline-block;
    text-transform: uppercase;
    font-size: $rem-14px;
    letter-spacing: 0.7px;

    .text {
      @extend .f-extra-bold;
    }
    .arrow-link {
      margin-left: 10px;
      @include transition(margin-left 0.3s ease-in-out);
    }

    &:hover, &:focus {
      opacity: 0.7;
      @include transition(opacity 0.3s ease-in-out);
      .arrow-link {
        margin-left: 20px;
        @include transition(margin-left 0.3s ease-in-out);
      }
    }
  }

  &:hover, &:active {
    opacity: 0.7;
    color: inherit;
    @include transition(opacity 0.3s ease-in-out);
  }
}
