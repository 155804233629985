/*!
 *  TYPOGRAPHY
 */

h1 {
  font-size: $rem-40px + $rem-34px;
  font-weight: 600;
  line-height: 100%;
  margin-top: 0;
  color: $text-black;
  @extend .f-regular;
  @include media-breakpoint-down(md){
    font-size: $rem-40px + $rem-20px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-40px + $rem-10px;
  }
}

h2 {
  margin: 0;
  font-size: $rem-40px + $rem-10px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-36px + $rem-10px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-30px + $rem-10px;
  }
}

h3 {
  font-size: $rem-40px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-36px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-30px;
  }
}

h4 {
  font-size: $rem-34px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-30px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-24px;
  }
}

h5 {
  font-size: $rem-30px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-14px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-12px;
  }
}

h6 {
  font-size: $rem-26px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-8px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}

.institutional-background {

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    color: $white;
  }
}

p {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: $rem-16px;
  // color: $grey2;
  // font-weight: 400;
  // line-height: 1.5em;
}

strong {
  color: $text-black;
}


// colors
.cl-white {
  color: $white;
}
.cl-institutional {
  color: $institutional;
}
.cl-grey {
  color: $text-grey;
}
.cl-grey-light {
  color: $grey4;
}
.cl-black {
  color: $black
}
.cl-orange-dark {
  color: $dark-orange;
}

// background color
.bk-white {
  background-color: $white;
}
.bk-institutional {
  background-color: $institutional;
}
.bk-grey {
  background-color: $text-grey;
}
.bk-light-grey {
  background-color: $grey7;
}
.bk-black {
  background-color: $black;
}
.bk-gradient {
  @include background($gradient);
}
.bk-gradient-blue {
  @include background($gradientBlue);
}


.very-small {
  font-size: $rem-12px !important;
  line-height: 1.5em;
  @include media-breakpoint-down(md) {
    font-size: $rem-10px !important;
  }
}

.small {
  font-size: $rem-14px !important;
  line-height: 1.5em;
  @include media-breakpoint-down(md) {
    font-size: $rem-12px !important;
  }
}

.medium {
  font-size: $rem-20px !important;
  line-height: 1.3em;

  @include media-breakpoint-down(md) {
    font-size: $rem-18px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: $rem-16px !important;
  }
}

.large {
  font-size: $rem-26px !important;
  line-height: 1.3em;
  @include media-breakpoint-down(md) {
    font-size: $rem-24px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: $rem-22px !important;
  }
}

.very-large {
  font-size: $rem-28px !important;
  line-height: 1.3em;
  @include media-breakpoint-down(md) {
    font-size: $rem-26px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: $rem-24px !important;
  }
}

.big {
  font-size: $rem-30px !important;
  line-height: 1.3em;
  @include media-breakpoint-down(md) {
    font-size: $rem-28px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: $rem-26px !important;
  }
}


.f-light {
  font-weight: 400 !important;
}

.f-regular {
  font-weight: 600;
}

.f-semibold {
  font-weight: 700;
}

.f-bold {
  font-weight: 800;
}

.f-extra-bold {
  font-weight: 900;
}

.f-black {
  font-weight: 900;
}

.title-maring-small{

  @include media-breakpoint-down(xs) {
    margin: 5px 0px 5px 0px;
  }
  
}

.title-maring-regular{

  @include media-breakpoint-down(xs) {
    margin: 30px 0px 30px 0px;
  }

}

.title-maring-extralarge{

  @include media-breakpoint-down(xs) {
    margin: 50px 0px 50px 0px;
  }

}