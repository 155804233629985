/*!
 *  PAGES
 */
main {
  width: $full;
  height: auto;
  position: relative;
  &.home {
    .about-chuzeat {
      position: relative;
      .wrapp-muffin {
        position: absolute;
        top: -30%;
        left: 16%;
        z-index: 3;
        @include media-breakpoint-down(sm) {
          left: 0;
        }
        @include media-breakpoint-down(xs) {
          top: -13%;
          right: 0%;
        }
        img {
          max-width: 170px;
          @include media-breakpoint-down(sm) {
            max-width: 120px;
          }
          @include media-breakpoint-down(xs) {
            max-width: 80px;
          }
        }
      }
      .wrapp-chatbot {
        position: absolute;
        top: -30%;
        right: 5%;
        z-index: 3;
        @include media-breakpoint-down(xs) {
          top: -13%;
          right: 0%;
        }
        img {
          max-width: 150px;
          @include media-breakpoint-down(sm) {
            max-width: 120px;
          }
          @include media-breakpoint-down(xs) {
            max-width: 80px;
          }
        }
      }
      .wrapp-download {
        position: relative;
        top: -50px;
      }
      .wrapp-icons {
        .circle {
          position: absolute;
          width: 70%;
          min-height: 200px;
          @include border-radius(100%);
          @include background($gradient);
          z-index: -1;
          @include media-breakpoint-down(md) {
            width: 90%;
            top: 5%;
            left: 15%;
          }
          @include media-breakpoint-down(sm) {
            width: 70%;
            top: 0%;
          }
        }
        .wrapp-image {
          position: relative;
          margin: 0;
          text-align: center;
          img {
            max-width: 500px;
            width: 100%;
            @include media-breakpoint-down(xs) {
              max-width: 450px;
            }
          }
          &.first {
            margin-top: 50px;
            margin-left: 50px;
            z-index: 2;
            @include media-breakpoint-down(md) {
              margin-top: 0;
            }
            @include media-breakpoint-down(sm) {
              margin-top: 0;
              margin-left: 0px;
            }
          }
          &.second {
            position: relative;
            top: -70px;
            z-index: 1;
          }
        }
      }
    }
    .how-works {
      position: relative;
      padding-bottom: 200px;
      @include media-breakpoint-down(md) {
        padding-bottom: 10px;
        margin-top: 100px;
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
      }
      .wrapp-cards {
        .wrapp-card {
          position: relative;
          height: 100%;
          &.card-01 {
            .ch-card.first {
              margin-top: 0px;
            }
          }
          &.card-02 {
            .ch-card.first {
              margin-top: 80px;
            }
          }
          &.card-03 {
            .ch-card.first {
              margin-top: 160px;
            }
          }
          .ch-card {
            width: 90%;
            margin: 0 auto;
            &.last {
              margin-top: 80px;
            }
            @extend .extend-card;
            @include box-shadow(0px 0px 25px rgba(250, 69, 59, 0.10));
            @include media-breakpoint-down(md) {
              width: 100%;
            }
            @include media-breakpoint-down(sm) {
              margin-top: 0px !important
            }

            .wrapp-cup {
              position: absolute;
              top: 25%;
              left: -60px;
              @include transform(rotate(-55deg));
              img {
                max-height: 110px;
              }
            }
            .wrapp-header {
              img {
                max-height: 160px;
                height: 100%;
                margin: 0 auto;
                display: block;
              }
            }

            &.flip {
            	position: relative;
              -webkit-perspective: 600px;
              perspective: 600px;
              .front, .back {
                @include transform-style(preserve-3d);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                @include transition(.4s ease-in-out);
                display: block;
              }
              .front {
                transform: rotateY(0deg);
              }
              .back {
                position: absolute;
                background: $institutional;
                padding: 25px 15px;
                @include border-radius(10px);
                opacity: 0;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                @include transform(rotateY(-180deg));

                .wrapp-header {
                  display: block;
                  width: 40px;
                  height: 40px;
                  background: #ce3028;
                  color: $white;
                  line-height: 42px;
                  border: none;
                  border-radius: 20px;
                  text-align: center;
                  font-size: $rem-20px;
                  @extend .f-semibold;
                }
              }
              &:hover, &.flipped {
                .front {
                    @include transform(rotateY(180deg));
                }
                .back {
                    opacity: 1;
                    @include transform(rotateY(0deg));
                }
              }
            }
          }
          .wrapp-muffin {
            position: absolute;
            top: 100px;
            right: -50px;
            @include transform(rotate(75deg));
            img {
              max-width: 100px;
            }
          }
        }
        .progress-status {
          position: relative;
          width: 80%;
          margin: 40px auto 0px;
          .swiper-pagination-progressbar {
            @extend .extend-swiper-pagination;
          }
        }
      }
      .wrapp-illustration-hand {
        position: absolute;
        bottom: -100px;
        left: -90px;
        @include media-breakpoint-down(md) {
          left: -150px;
        }

        img {
          max-width: 75%;
          @include media-breakpoint-down(md) {
            max-width: 65%;
          }
        }
      }
    }
    .easy-solution {
      .wrapp-conposition {
        position: relative;
        .circle {
          position: absolute;
          top: 0;
          .big-circle {
            width: 80%;
            @include background($gradient);
          }
          .small-circle {
            width: 200px;
            @include background($gradient);
          }
        }
      }
    }
    .cta-store {
      @extend .extand-cta-store;
    }
  }
  &.chuzeat-app {
    .chuzeat-services {
      position: relative;
      .wrapp-iphones {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        .wrapp-image {
          position: relative;
          margin: 0 auto;
          width: auto;
          height: auto;
          img {
            max-width: 600px;
            width: 100%;
            position: relative;
            @include media-breakpoint-down(md) {
              max-width: 500px;
            }
            @include media-breakpoint-down(sm) {
              display: block;
              margin: 0 auto;
              max-width: 450px;
            }
            @media (max-width: 500px) {
              max-width: 80%;
              margin: 0 auto;
            }
            @media (max-width: 450px) {
              max-width: 90%;
              margin: 0 auto;
            }
            @media (max-width: 400px) {
              max-width: 100%;
              margin: 0 auto;
            }
          }

          &.first {
            top: 0;
            left: -20px;
            z-index: 2;
            @include media-breakpoint-down(md) {
              left: 0;
              img {
                left: 0;
              }
            }
          }
          &.second {
            position: absolute;
            top: 70px;
            left: -400px;
            width: 100%;
            @include media-breakpoint-down(md) {
              left: 0;
              img {
                top: 250px;
                left: 0;
              }
            }
            @media (max-width: 450px) {
              img {
                top: 200px;
              }
            }
            @media (max-width: 400px) {
              img {
                top: 180px;
              }
            }
          }
          &.third {
            top: 0px;
            left: 60px;
            z-index: 1;
            @include media-breakpoint-down(md) {
              left: 0;
              img {
                left: 0;
                top: -150px;
              }
            }
            @media (max-width: 500px) {
              img {
                top: -75px;
              }
            }
            @media (max-width: 450px) {
              img {
                top: -100px;
              }
            }
            @media (max-width: 360px) {
              img {
                top: -80px;
              }
            }
            @media (max-width: 320px) {
              img {
                top: -60px;
              }
            }
          }
        }
      }
      .circle {
        position: absolute;
        max-width: 700px;
        width: 100%;
        top: 0;
        left: -15%;
        z-index: 1;
        @include media-breakpoint-down(md) {
          left: 10%;
        }
        @include media-breakpoint-down(sm) {
          max-width: 600px;
        }
        @include media-breakpoint-down(xs) {
          top: -50px;
          left: 40%;
          max-width: 500px;
          width: 500px;
          height: 500px !important;
        }
      }

      .wrapp-text-block {
        @include media-breakpoint-down(md) {
          margin-top: 100px;
        }
      }
    }
    .qr-code {
      .col-wrapp-media {
        position: relative;
        .wrapp-counter {
          position: absolute;
          bottom: 20%;
          left: 70px;
          font-size: $rem-40px + $rem-40px + $rem-16px;
          @extend .f-bold;
          color: $institutional;
          opacity: 0.1;
          line-height: 100px;
          z-index: -1;

          @include media-breakpoint-down(xs) {
            bottom: 10%;
            left: 40px;
            font-size: $rem-40px + $rem-8px;
          }
        }
      }
    }
    .manage-order {
      .wrapp-media {
        position: relative;
        .circle {
          position: absolute;
          left: 0;
          bottom: 100px;
          width: 350px;
          height: 350px;
          @include background($gradient);
          @include border-radius(100%);
          z-index: -1;
          @include media-breakpoint-down(sm) {
            width: 300px;
            height: 300px;
            bottom: 0;
            left: 15%;
          }
          @include media-breakpoint-down(xs) {
            left: 10%;
          }
          @media (max-width: 430px) {
            left: 0px;
          }
        }
        .small-circle {
          position: absolute;
          top: -20%;
          right: 20%;
          width: 200px;
          height: 200px;
          @include background($gradient);
          @include border-radius(100%);
          z-index: -1;
          @include media-breakpoint-down(sm) {
            right: 25%;
          }
          @include media-breakpoint-down(xs) {
            top: -10%;
            right: 25%;
            width: 100px;
            height: 100px;
          }
        }

        .wrapp-image {
          text-align: center;
          position: relative;
          z-index: 2;
          @include transform(rotate(15deg));
          img {
            position: relative;
            top: -20px;
            max-width: 250px;
            width: 100%;
            @include media-breakpoint-down(sm) {
              max-width: 200px;
            }
            @include media-breakpoint-down(xs) {
              max-width: 180px;
            }
          }
        }
      }
    }
    .mission {
      .plate-block-interaction {
        position: relative;

        .plate-list {
          position: relative;
          z-index: 2;
          padding-left: 50px;
          display: inline-block;
          .circle {
            width: 100px;
            height: 100px;
            @include background($gradient);
            position: absolute;
            top: calc(50% - 50px);
            left: 0;
            z-index: -1;
            @include border-radius(50%);
          }
          ul {
            .plate-wrapper {
              box-shadow: 0px 0px 10px #e2e2e2;
              @include border-radius(10px);
              margin-bottom: 15px;
              display: inline-block;
              max-width: 300px;
              width: 100%;
              overflow: hidden;

              a {
                background: $white;
                display: block;
                padding: 15px 20px;
                color: $black;
                border: 1px solid $white;
                @include border-radius(10px);
                @include transition(all 0.4s ease-in-out);

                &:hover {
                  opacity: 1;
                  border-color: $institutional;
                }
              }

              &.active {
                a {
                  background: $institutional;
                  @include transition(all 0.4s ease-in-out);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .manage-payment {
      position: relative;
      padding: 100px 0px;
      .bk-section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $footer-grey;
      }

      .manage-payment-content {
        position: relative;
        z-index: 2;
        .manage-payment-slide {
          .payment-block {
            position: relative;
            z-index: 2;
            .block-image {
              figure {
                text-align: center;
                img {
                  max-height: 600px;
                  @include media-breakpoint-down(md) {
                    max-height: 550px;
                  }
                  @include media-breakpoint-down(sm) {
                    max-height: 500px;
                  }
                  @include media-breakpoint-down(xs) {
                    max-height: 450px;
                  }
                }
              }
            }
            .card-payament {
              margin-top: 30px;
              @include media-breakpoint-down(md) {
                position: absolute;
                bottom: 50px;
                left: 15%;
                width: 70%;
                padding: 0px 10px;
              }
              @include media-breakpoint-down(sm) {
                left: 10%;
                width: 80%;
              }
              @include media-breakpoint-down(xs) {
                left: 0;
                width: 100%;
              }
              .content {
                padding: 30px 15px;
                background: $white;
                @include border-radius(25px);
                text-align: center;
                .card-title {
                  @extend .f-semibold;
                  font-size: $rem-14px;
                }
                @include media-breakpoint-down(md) {
                  @include box-shadow(0px 0px 10px $grey4);
                }
              }
            }
          }
          .progress-status {
            margin-top: 50px;
            .swiper-pagination-progressbar {
              @extend .extend-swiper-pagination;
            }
          }
        }
      }
    }
    .ai {
      .wrapp-intro-section {
        .wrapp-illustration {
          @include media-breakpoint-down(md) {
            @include transform(scale(1.5));
            margin: 100px 0px 60px;
          }
        }
      }
    }
    .cta-store {
      position: relative;
      @extend .extand-cta-store;
      .wrapp-cup {
        position: absolute;
        bottom: -50px;
        right: 15%;
        img {
          width: 70px;
          @include media-breakpoint-down(xs) {
            width: 45px;
          }
        }
      }
    }
  }
  &.business {
    .business-services {
      position: relative;
      .wrapp-media {
        .ipad-mok{
          position: absolute;
          width: 100%;
          right: 0;
          bottom: 50px;
          text-align: center;
          z-index: 2;
          @include media-breakpoint-down(sm) {
            bottom: 0px;
            top: 0px;
          }
          img {
            //max-width: 500px;
            width: 100%;

            @include media-breakpoint-down(xs) {
              max-width: 400px;
            }
            @media (max-width: 450px){
              max-width: 90%;
            }
          }
        }
        .circle {
          position: relative;
          width: 70%;
          top: -30px;
          left: 30px;
          @include media-breakpoint-down(md) {
            width: 80%;
            left: 10px;
          }
          @include media-breakpoint-down(sm) {
            width: 70%;
            left: 30px;
          }
        }
      }
    }
    .tabs {
      position: relative;
      .circle-wrapp-card {
        position: absolute;
        height: 120%;
        top: -10%;
        margin-left: -20px;
      }
      .circle-squared {
        border-radius: 0;
        width: 102vw;
      }

      .wrapp-card {
        margin-bottom: 50px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0px;
        }
        .ch-card {
          width: 90%;
          margin: 0 auto;
          height: 100%;
          @extend .extend-card;
          @include box-shadow(0px 0px 40px rgba(64, 88, 195, 0.10));
          @include media-breakpoint-down(md) {
            width: 100%;
          }

          .wrapp-header {
            .icon-card {
              max-width: 50px;
              width: 100%;
            }
          }
        }
      }
      .wrapp-mobile-slide {
        position: relative;
        .circle-wrapp-card {
          position: absolute;
          height: 150%;
          top: -25%;
          left: 100%;
          @include media-breakpoint-down(xs) {
            left: 80%;
          }
        }
        .swiper-container {
          padding: 20px 0px;
          overflow: inherit !important;
          .progress-status {
            width: 100%;
            margin-top: 30px;
            .swiper-pagination-progressbar {
              @extend .extend-swiper-pagination;
            }
          }
        }
      }
    }
    .solution {
      .wrapp-media {
        .wrapp-ipad {
          //position: absolute;
          top: 100px;
          //width: 100%;
          //right: -100px;
          //transform: rotate(-15deg);
          @include media-breakpoint-down(md) {
            top: 0;
          }
          /*@include media-breakpoint-down(sm) {
            top: 80px;
          }*/
          img {
            max-height: 600px;
            @include media-breakpoint-down(sm) {
              max-width: 450px;
              width: 90%;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
            @include media-breakpoint-down(xs) {
              max-width: 380px;
            }
          }
        }
        .circle {
          position: relative;
          top: 0;
          width: 90%;

          @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 400px;
            height: 400px;
            margin: 0 auto;
          }
          @include media-breakpoint-down(xs) {
            max-width: 350px;
            height: 350px;
          }
        }
      }
      .col-wrapp-media {
        .analysis {
          position: relative;
          width: 110%;
          left: -18%;
        }
      }
    }
    .main-feature {
      padding: 80px 0px;
      background-color: $footer-grey;

      .main-gallery {
        position: relative;
        margin-top: 100px;
        .big-circle {
          position: absolute;
          bottom: -50px;
          left: -50px;
          width: 400px;
          @include background($gradientBlue);
        }
        .medium-circle {
          position: absolute;
          top: -50px;
          right: 30%;
          width: 200px;
          @include background($gradientBlue);
        }
        .small-circle {
          position: absolute;
          bottom: 0px;
          right: 0;
          width: 90px;
          @include background($gradientBlue);
        }

        .swiper-container {
          // overflow: inherit !important;
          .swiper-slide {
            position: relative;
            width: 100%;
            padding: 0% 1% 0% 1%;


            .slide-title {

              padding-top: 16px;
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 44px;

              @include media-breakpoint-down(xs) {
               font-size: 28px;
               line-height: 30px;

              }
            }

            .info-panel {
              padding: 8% 5% 1% 5%;
              margin-bottom: 5%;
              box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.04);
              background: $white;
              @include border-radius(30px);
              text-align: center;
              height: 100%;
              min-height: 250px;
              border: 3px;
              border-style: solid;
              border-color: #ffffff;
              @include media-breakpoint-down(md) {
                min-height: 270px;
              }
              @include media-breakpoint-down(sm) {
                min-height: 290px;
              }
              @include media-breakpoint-down(xs) {
                min-height: 270px;
                padding: 10% 5% 0% 5%;
              }

              .slide-icon {

                margin: 1%;
                img {
                  width: 60px;
                }
  
              }
            }

            .separator-normal {
              @include media-breakpoint-down(xs) {
                margin-top: 30px !important;
              }
            }
          }
        }
      }

      .wrapp-action-slide {
          margin-top: 50px;
          .business-prev {
            @include transform(rotate(-180deg));
          }
          .business-next, .business-prev {
            cursor: pointer;
            &.swiper-button-disabled {
              opacity: 0.4;
            }
            img {
              width: 50px;
            }
          }
          .progress-status {
            width: 100%;
            margin: 0px 50px;
            .swiper-pagination-progressbar {
              @extend .extend-swiper-pagination;
            }
          }
      }
    }

    .choose-plan {
      background-color: #f8f8f8;
      padding-top: 150px;
      padding-bottom: 150px;
      @include media-breakpoint-down(sm) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      .plan-table {
        padding-left: 0; padding-right: 0;
        border-radius: 7px; overflow: hidden;

        box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.2);
        @include media-breakpoint-down(md) {
          box-shadow: none;
        }

        .wrapp-plan {
          border-right: 1px solid rgba(167, 165, 165, 0.3);
          padding-left: 30px; padding-right: 30px;
          @include media-breakpoint-down(sm) {
            border-right: none;
            border-bottom: 1px solid rgba(167, 165, 165, 0.3);
            margin-bottom: 50px;
          }
          &:last-child { border: none; }
        }

        &__header {
          @include background($gradientBlue);
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        &__body { padding: 50px;
          @include media-breakpoint-down(sm) {
            padding-bottom: 0;
          }
        }
        &__footer { padding: 0 50px 50px; text-align: center;
          @include media-breakpoint-down(sm) {
            display: none;
            padding-bottom: 0;
          }
        }
        &__bigplayer {
          padding: 0 50px 50px;
          text-align: center;


          .bigplayer {
            border: 1px solid rgba(167, 165, 165, 0.3);
            border-radius: 10px;
            padding: 20px 30px;
            display: flex;
            //text-align: left;
            flex-direction: column;

            @include media-breakpoint-down(sm) {
              //flex-direction: column;
            }

            .bigplayer__left {
              //max-width: 250px;
              //margin-right: 30px;
              @include media-breakpoint-down(sm) {
                max-width:none;
                margin-right: 0;
                text-align: center;
                }
              }
            .bigplayer__right {
              flex-grow: 2;
              @include media-breakpoint-down(sm) {
                text-align: center;
              }
              .btnn-plans {
                max-width: 330px;
                font-size: 15px;
                line-height: 45px;
                @include media-breakpoint-down(sm) {
                  line-height: 22px;
                  padding: 8px 0;
                  margin-bottom: 25px;
                }
              }
            }
            &__title {
              font-size: 30px;
              line-height: 1.5;
              color: #000;
              font-weight: 700;
              margin-bottom: 4px;
            }
            &__subtitle {
              font-size: 19px;
              line-height: 1.35;
              color: #000;
              font-weight: 400;
              margin-bottom: 10px;
            }
            &__text {
              font-size: 15px;
              line-height: 1.2;
              color: #000;
              font-weight: 600;
            }
          }
        }

        &__title {
          min-height: 110px;
          h5 { font-size: 38px; line-height: 45px;}
          p { font-size: 19px; line-height: 23px; }
          @include media-breakpoint-down(sm) {
            min-height: auto;
            margin-bottom: 30px;
          }
        }
        &__price {
          min-height: 160px;
          font-size: 22px;
          display: flex;
          align-items: flex-start;

          .plan-table__pricecol:first-child {

             //min-width: 150px;
             padding-right: 25px;
             margin-right: 35px;
             border-right: 1px solid rgba(167, 165, 165, 0.3);

          }
          .price-medium { font-size: 22px; }
          .price-detail { font-size: 12px;}
          @include media-breakpoint-down(sm) {
            min-height: auto;
            margin-bottom: 30px;
          }
          .discount { color: #aaa; text-decoration: line-through; }
        }
        &__info { font-size: 12px; line-height: 16px; color: #717171; }
        &__features {
          margin-bottom: 20px;
          ul, li { list-style: none; margin: 0; padding: 0; }
          ul { margin: 17px 0 21px; }
          li {
            margin-bottom: 14px; font-size: 13px; line-height: 16px;
            .icon img { display: inline-block; vertical-align: bottom; width: 16px; height: 16px; margin-right: 3px; position: relative; top: -1px;}
            &.is-included { color: #000000; }
            &.is-excluded { color: #D2D2D2; text-decoration: line-through; }
            &.is-optional { color: #646363; }
          }
          .is-optional-legend { font-size: 11px; font-weight: 400; line-height: 16px; color: #717171;  }
        }

        .btnn-plans {
          max-width: 280px;
          margin: 0 auto;
          font-size: 20px;
          line-height: 45px;
          text-align: center;
          @include media-breakpoint-down(SM) {
            margin-bottom: 50px;
            line-height: 1.1em;
            padding: 10px;
          }
        }
      }
    }



    .became-partner-cta {
      @include background($gradientBlue);
      padding: 100px 0px;
      &.separator-large {margin-top: 0;}

      .wrapp-title {
        br {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      .wrapp-user-iteraction {
        position: relative;
        .shadow-panel {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: $white;
          @include border-radius(40px);
          @include box-shadow(0px 0px 10px $grey4);
          @include transform(rotate(-7deg));
          z-index: 1;
        }
        .form-wrapp-all {
          position: relative;
          z-index: 2;
          .steps-block {
            background: $white;
            @include border-radius(40px);
            @include box-shadow(0px 0px 10px $grey4);
            padding: 70px 40px;
            min-height: 250px;
            @include media-breakpoint-down(xs) {
              padding: 50px 25px;
            }
            .wrapp-header {
              .progress-status {
                width: 100%;
                margin: 0px 20px;
                .swiper-pagination-progressbar {
                  @extend .extend-swiper-pagination;
                }
              }
              .cancel-request {
                width: 25px;
                height: 25px;
                background: none;
                @include border-radius(15px);
                line-height: 23px;
                font-size: $rem-22px;
                padding: 0;
                color: $grey3;
                border-color: $grey3;
              }
            }
            .wrapp-body {
              .swiper-slide {
                &.r-name {
                  .increment-number {
                    font-size: $rem-40px + $rem-10px !important;
                  }
                }
                &.thank-you {
                  .th-image {
                    max-width: 120px;
                    @include media-breakpoint-down(sm) {
                      max-width: 100px
                    }
                  }
                }
                .interact-block {
                  margin: 50px 0px 40px;
                  @include media-breakpoint-down(xs) {
                    margin: 40px 0px 20;
                  }
                  .start-form {
                    background: none;
                    border: none;
                    padding: 0;
                  }
                }
                .more-less-block {
                  .btnn-interaction {
                    width: 35px;
                    height: 35px;
                    line-height: 33px;
                    padding: 0;
                    text-align: center;
                    font-size: $rem-30px;
                    @include media-breakpoint-down(xs) {
                      width: 30px;
                      height: 30px;
                      line-height: 28px;
                      font-size: $rem-26px;
                    }

                    &.btnn-less {
                      border-color: $grey3;
                      color: $grey3;
                    }
                    &.btnn-more {
                      border-color: $institutional;
                      color: $institutional;
                    }
                  }
                }
                .increment-number {
                  font-size: $rem-40px + $rem-14px;
                  line-height: 100%;
                  width: 100%;
                  border: none;
                  text-align: center;
                  @extend .f-semibold;
                  -moz-appearance: textfield;
                  border-bottom: 1px solid $institutional;
                  @include border-radius(0px);
                  @include media-breakpoint-down(xs) {
                    font-size: $rem-34px + $rem-8px;
                  }

                  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                  }
                }
              }
              .form-check {
                text-align: left;
              }
            }
            .wrapp-footer {
              .go-ahead {
                @include box-shadow(0px 0px 4px $institutional);
              }
            }
          }
          .wrapp-cup {
            position: absolute;
            top: -70px;
            right: 15%;
            @include media-breakpoint-down(xs) {
              right: 5%;
            }
            img {
              width: 70px;
              @include media-breakpoint-down(xs) {
                width: 50px;
              }
            }
          }
          .wrapp-king {
            position: absolute;
            left: -18%;
            bottom: 15%;
            @include media-breakpoint-down(sm) {
              left: -5%;
              bottom: 0%;
            }
            img {
              width: 100px;
            }
          }
        }
      }
    }

    .press {

      .press-gallery {
        margin-top: 100px;
        a,a:hover { opacity: 1; }

        .wrapp-card {
          margin-bottom: 60px;
          text-align: center;
        }
      }

    }
  }
  &.chuzeat-social {
    .our-project {
      .wrapp-project-slide {
        position: relative;
        .wrapp-slide-and-circle {
          .behind-slide {
            position: relative;
            background: $white;
            margin: 0 auto;
            @include box-shadow(0px 0px 50px rgba(250, 69, 59, 0.10));
          }
          .wrapp-slider-container {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
        .wrapp-project-navigation {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;

          @include media-breakpoint-down(sm) {
            position: relative;
          }

          .wrapp-project-next, .wrapp-project-prev {
            position: absolute;
            top: calc(50% - 12.5px);
            @include media-breakpoint-down(sm) {
              position: relative;
              top: 0;
            }
            cursor: pointer;
            .swiper-button-disabled {
              opacity: 0.4;
            }
            img {
              width: 50px;
              @include media-breakpoint-down(sm) {
                width: 45px;
              }
            }
          }
          .wrapp-project-prev {
            left: 0;
            .project-prev {
              @include transform(rotate(-180deg));
            }
          }
          .wrapp-project-next {
            right: 0;
            @include media-breakpoint-down(sm) {
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
  &.about {
    .team {
      .wrapp-slider-container {
        margin-top: 100px;

        .swiper-container {
          overflow: inherit;
        }
      }
      .wrapp-team-navigation {
        position: relative;
        .wrapp-team-next, .wrapp-team-prev {
          cursor: pointer;
          .swiper-button-disabled {
            opacity: 0.4;
          }
          img {
            width: 45px;
          }
        }
        .wrapp-team-prev {
          .team-prev {
            @include transform(rotate(-180deg));
          }
        }
        .wrapp-team-next {
          margin-left: 25px;
        }
      }
    }
    .mission {
      .col-wrapp-media {
        .analysis {
          position: relative;
          width: 110%;
          left: -18%;
        }
      }
    }
  }
  &.faq {
    .faq-list {
      .list-item {
        padding: 25px 0px;
        margin: 10px 0px;
        border-bottom: 1px solid $color-before-title;

        .wrapp-question {
          color: $text-grey;
        }

        .wrapp-answer {
          font-size: $rem-14px;
          margin-top: 20px;
        }
      }
    }
  }
  &.contacts {
    .addresses {
      .wrapp-icon-address {
        img {
          width: 100px;
        }
      }
    }
  }
}


.col-1.list-element{

  @media only screen and (max-width: 1500px) {

    margin-right: 20px;
    
  }

  @media only screen and (max-width: 990px) {
    
    margin-right: 0px;

  }
 
}

.fesr-campaign{
  .fesr-logo{
    margin: 50px 10px 0 10px;
    & img{    
      max-height: 80px;
  }
  }
	.fesr-logo.big{
      & img{
        @media only screen and (min-width: 990px) {
          max-height: 150px;
        }    
         
      }
		}
  }


.title{

      margin: 50px 0 0 0;
      padding: 0px;
}
