/*!
 *  STRUCTURE
 */

html {
  overflow-x: hidden;
  font-size: $full;
  scroll-behavior: smooth;
}

html.no-overflow {
  width: $full;
  height: $full;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

html, body {
  height: auto;
  font-family: $main-font;
  @extend .f-light;
  @include text-size-adjust(none);
  min-height: $full;
  background: $white;
  color: $text-black;
  line-height: 1.6rem;
  overflow-x: hidden;
}

body {
  &.no-scroll {
    overflow: hidden;
  }
  &.policy {
    .main-block {
      @include filter(none);
    }
    #fullscreen-menu {
      background-color: rgba(255, 255, 255, .9);
    }
  }
}

// loader animation
.spinner-loader-page {
  width: 64px;
  height: 64px;
  position: fixed;
  top: $medium;
  left: $medium;
  margin-top: -32px;
  margin-left: -32px;
  padding-top: 10px;
  text-align: center;
  @include border-radius($medium);
  z-index: 2;
}

.wrapp-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 999;
  .lds-ripple {
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    top: calc(50% - 32px);

    div {
      position: absolute;
      border: 4px solid $institutional;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }

  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
}

// manage opacity on menu open
// .main-block {
//   opacity: 1;
//   @include transition(all 0.4s ease-in-out);
//   transition-delay: 0.6s;
//   &.opacity {
//     opacity: 0;
//     @include transition(all 0.6s ease-in-out);
//   }
// }
.main-block {
  position: relative;
  @include filter(blur(0px));
  transition-delay: 0.3s;
  @include transition(all 0.4s ease-in-out);
  &.blur {
    @include filter(blur(30px));
    @include transition(all 0.6s ease-in-out);
  }
}

.before-title {
  margin-bottom: 10px;
  .badge {
    display: inline-block;
    width: 10px;
    height: 10px;
    @include border-radius(5px);
  }
  .info-small-text {
    font-size: $rem-14px;
    color: #b5b5b5;
    @extend .f-semibold;
    letter-spacing: 0.7px;

    @include media-breakpoint-down(sm) {
      font-size: $rem-11px;
      letter-spacing: 0.5px;
    }
  }
}

.app-download-text {
  font-size: $rem-12px;
  @extend .f-semibold;
  color: #b5b5b5;
  @include media-breakpoint-down(sm) {
    font-size: $rem-11px;
  }
}

.separator-large {
  margin-top: 70px;
  @include media-breakpoint-down(md) {
    margin-top: 110px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 100px;
  }
  @include media-breakpoint-down(xs) {
    margin-top: 80px;
  }
}
.separator-normal {
  margin-top: 60px;
  @include media-breakpoint-down(md) {
    margin-top: 60px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 60px;
  }
  @include media-breakpoint-down(xs) {
    margin-top: 30px;
  }
}

.app-store, .play-store {
  img {
    width: 130px;
    height: 40px;
  }
}

.wrapp-illustration {
  &.reverse {
    svg {
      @include transform(scaleX(-1));
      -ms-filter: fliph; /*IE*/
      filter: fliph; /*IE*/
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

.logo-chuzeat {
  width: 170px;
}

/* ---------------------------- Placeholder ----------------------------------- */
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $white;
}
input::-moz-placeholder { /* Firefox 19+ */
  color: $white;
}
input:-ms-input-placeholder { /* IE 10+ */
  color: $white;
}
input:-moz-placeholder { /* Firefox 18- */
  color: $white;
}

/* ---------------------------- Selections ----------------------------------- */
::-moz-selection {
  background: $institutional;
  color: $white;
}

::selection {
  background: $institutional;
  color: $white;
}

/* ---------------------------- Reset ----------------------------------- */
.notransition {
  @include transition(none !important);
}

* {
  text-shadow: none;
  outline: medium none !important;
}

*:before, *:after {
  @include box-sizing(border-box);
}

*:hover {
  @include transition(0s);
}

a:hover, a:focus {
  text-decoration: none;
}

.clear {
  clear: both;
}


/* ---------------------------- Basic components ----------------------------------- */
.circle {
  width: 100%;
  @include border-radius(100%);
}



/* ---------------------------- Basic components ----------------------------------- */

.moveRight {
    -webkit-animation-name: moveRight;
    animation-name: moveRight;
}

@-webkit-keyframes moveRight {
  0% { opacity:0; right: -400px;}
  to { opacity:1; right: -100px; }
}
@keyframes moveRight {
  0% { opacity:0; right: -400px;}
  to { opacity:1; right: -100px; }
}

.delay0 {
  -webkit-animation-delay: -0.1s;
  animation-delay: 0.1s;
}

.delay {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay1 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay3 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay4 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay5 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay6 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay7 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay8 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay9 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay10 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay11 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay12 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

// ANIMATION


// EXPLORER tag-list
@media all and (-ms-high-contrast:none) {
/* IE10 */
    #circular, #ellipsis {
      height: 300px;
    }

    .wrapp-card .card-image img {
      height: 250px!important;
    }
}

/* IE11 */
*::-ms-backdrop, #circular, #ellipsis {
  height: 300px;
}
*::-ms-backdrop, .wrapp-card .card-image img {
      height: 250px!important;
}

.how-it-works-block ul {

  text-align: left;

}