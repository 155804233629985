#pp-section {

    #iubenda_policy h1,
    #iubenda_policy h2,
    #iubenda_policy h3,
    #iubenda_policy h4,
    #iubenda_policy h5,
    #iubenda_policy h6,
    #iubenda_policy p,
    #iubenda_policy blockquote,
    #iubenda_policy pre,
    #iubenda_policy a,
    #iubenda_policy abbr,
    #iubenda_policy acronym,
    #iubenda_policy address,
    #iubenda_policy cite,
    #iubenda_policy code,
    #iubenda_policy del,
    #iubenda_policy dfn,
    #iubenda_policy em,
    #iubenda_policy img,
    #iubenda_policy q,
    #iubenda_policy s,
    #iubenda_policy samp,
    #iubenda_policy small,
    #iubenda_policy strike,
    #iubenda_policy strong,
    #iubenda_policy sub,
    #iubenda_policy sup,
    #iubenda_policy tt,
    #iubenda_policy var,
    #iubenda_policy dd,
    #iubenda_policy dl,
    #iubenda_policy dt,
    #iubenda_policy li,
    #iubenda_policy ol,
    #iubenda_policy ul,
    #iubenda_policy fieldset,
    #iubenda_policy form,
    #iubenda_policy label,
    #iubenda_policy legend,
    #iubenda_policy button,
    #iubenda_policy table,
    #iubenda_policy caption,
    #iubenda_policy tbody,
    #iubenda_policy tfoot,
    #iubenda_policy thead,
    #iubenda_policy tr,
    #iubenda_policy th,
    #iubenda_policy td {
        font-family: $main-font !important;
        color: $black;
    }

    #delete_account {
        position: relative;
        padding: 25px 30px;
        margin: 0 auto;
        border-radius: 3px 3px 0 0;

        #iubenda_policy h2,
        #iubenda_policy h3 {
            color: $institutional;

            .unstyled & {
                color: $black;
            }
        }
    }

    #ppuser {

        #iubenda_policy h2,
        #iubenda_policy h3 {
            color: $institutional;

            .unstyled & {
                color: $black;
            }
        }
    }

    #ppbusiness {

        #iubenda_policy h2,
        #iubenda_policy h3 {
            color: #415FC2;

            .unstyled & {
                color: $black;
            }
        }
    }

    .selector {
        position: relative;

        .ppselector {
            list-style: none;
            position: fixed;
            top: 18%;
            right: 9%;
            margin-bottom: 0px;
            padding-inline-start: 0px;
            border:  1px solid rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 5px 10px;



            li {

                padding-left: 20px;
                margin: 10px 0;


                a {
                    color: $institutional;
                }

                &.ppbusinesslink {

                    a {
                        color: #415FC2;
                    }
                }

                &.activeblue {
                    padding-left: 20px;
                    background: url(/it/images/icons/ppblue.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }

                &.activered {
                    background: url(/it/images/icons/ppred.svg);
                    background-repeat: no-repeat;
                    background-position: left center;
                }
            }


        }
    }

}


@media screen and (max-width: 767px) {
    #pp-section .row.justify-content-center.wrapp-all-hero.h-100 {
        flex-direction: column-reverse;
        margin-top: 40px;

        .selector.col-sm-2 {
            text-align: center;
            max-width:100%;
        }
        .ppselector {
            position: relative;
            right:auto;
            max-width: 60%;
            margin: 0 auto;

            li {
                display: inline-block;
            }
        }
    }
}
