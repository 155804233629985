/*!
 *  EXTENSION
 */


.extend-card {
  position: relative;
  @include border-radius(10px);
  padding: 30px 20px;
  background: $white;
  @include box-shadow(0px 0px 10px $grey5);
}

.extand-cta-store {
  position: relative;
  .bk-cta {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wrapp-images-cta {
    height: 120%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;

    img {
      position: relative;
      max-height: 650px;
      top: 60px;
      display: block;
      margin: 0 auto;
      @include media-breakpoint-down(sm) {
        max-height: 550px;
        top: 30px;
      }
    }
  }
  .wrapp-text-cta {
    padding: 80px 0px;
  }
}

.extend-swiper-pagination {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: $grey5;
  @include border-radius(6px);
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  
  .swiper-pagination-progressbar-fill {
    @include background($gradient);
    position: absolute;
    border: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
  }
}
