.img-grey {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .8;

    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        opacity: 1;
    }
}
